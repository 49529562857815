<template>

<v-dialog
  :value="this.$store.state.updating"
  no-click-animation
  persistent
  width="250"
  height="100"
  class="pa-0"
>
  <v-card color="primary">
    <preloader message="please wait..." color="primary" padding="0" textColor="white" />
  </v-card>
</v-dialog>

</template>

<script>

export default {
  data() {
    return {
      show: true
    }
  }
}

</script>
